import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as AnalyticsService from '../../../../../../common/services/analytics';
import Profile from '../../../../../../common/components/profile';
import Card from '../../../../../../common/components/card';
import Icon from '../../../../../../common/components/icon';
import Text from '../../../../../../common/components/text';
import Permission from '../../../../../../common/components/permission';
import Dropdown from '../../../../../../common/components/dropdown';
import Attachment from '../../../../../../common/components/attachment';
import ConfirmButton from '../../../../../../common/components/confirm-button';
import Attachments from '../attachments';
import Files from '../files';
import Poll from '../../../poll';
import EditForm from '../../../../forms/edit-message';
import { EParentTypes, EComponentTypes } from '../../../../../../common/definitions';
import type { LoggedUser } from '../../../../../../common/types/objects';
import type { Message } from '../../../../types/objects';
import { useRouteMatch } from 'react-router';

const { EEventNames } = AnalyticsService;

type Props = {
  loggedUser: LoggedUser,
  item: Message,
  shouldHideParent: boolean,
  onVotePoll: (pollId: string, optionId: string, messageId: string, currentVoteResult: any[]) => Promise<any>,
  onDelete: (id: string) => Promise<void>,
  onToggleImportant: (id: string, important: boolean) => Promise<void>,
  onToggleMute: (id: string) => Promise<void>,
  translateMessage: (id: string) => Promise<void>,
  onMarkAsRead: () => void,
};

const DefaultMessageContentComponent = ({
  item,
  loggedUser,
  shouldHideParent,
  onVotePoll,
  translateMessage,
  onMarkAsRead,
  onToggleImportant,
  // @ts-expect-error
  onToggleComments,
  onToggleMute,
  onDelete,
}: Props) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [showTranslation, setShowTranslation] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);

  const toggleTranslation = async () => {
    if (showTranslation) {
      setShowTranslation(false);
    } else {
      if (!item.translated_text || !item.translated_text[loggedUser.translation_language.locale]) {
        setIsTranslating(true);

        await translateMessage(item.id);
      }

      setIsTranslating(false);
      setShowTranslation(true);
    }
  };

  const createContent = () => {
    if (isEditing) {
      return (
        <EditForm
          form={`edit-message/${item.id}`}
          message={item}
          onClose={() => setIsEditing(false)}
        />
      );
    }

    const { poll, user } = item;
    const text = (
      (loggedUser.translation_language && showTranslation && item.translated_text)
        && item.translated_text[loggedUser.translation_language.locale]
    ) || item.text;

    const canTranslate = item.content_language
      && loggedUser.translation_language
      && item.content_language !== loggedUser.translation_language.locale;

    return (
      <>
        {typeof text === 'string' && <Text enableReadMore>{text}</Text>}
        <Permission component={EComponentTypes.FEED_TRANSLATION}>
          {canTranslate && (
            <div className="Message__Translate" onClick={toggleTranslation} role="button">
              {showTranslation
                ? t('social:feed_item_show_original')
                : t(isTranslating ? 'social:feed_item_translating' : 'social:feed_item_show_translation')}
            </div>
          )}
        </Permission>
        {poll && (
          <div className="Message__content">
            <Poll
              poll={poll}
              message={item}
              loggedUser={loggedUser}
              // @ts-expect-error
              user={user}
              onVote={!item.publish_at && onVotePoll}
            />
          </div>
        )}
      </>
    );
  };

  // @ts-expect-error
  const { user, attachments, videos, files } = item;

  const showDropdown = item.actions?.can_edit
    || item.actions?.can_mute
    || item.actions?.can_mark_important
    || item.actions?.can_delete;

  const match = useRouteMatch();
  const isChannel = match.path === '/networks/:networkId/messages/:type/:id' &&
    // @ts-expect-error
    match?.params?.type === 'channel';

  return (
    <div className="Message__Inner Message--type-default">
      <Card.Header>
        <div className="Message__header__inner">
          <div className="pull-right">
            {item.is_important && <Icon type="star__filled" className="Message__Important" />}
            {showDropdown && (
              <Dropdown
                alignRight
                id={`Message__Actions-${item.id}`}
                toggle={<Icon type="more_vert" size="small" />}
              >
                {item.actions.can_edit && (
                  <Dropdown.Item onClick={() => setIsEditing(true)}>
                    {t('social:feed_item_message_edit_message')}
                  </Dropdown.Item>
                )}
                {item.is_important && !item.has_read && (
                  <Dropdown.Item onClick={onMarkAsRead}>
                    {t('social:feed_item_message_mark_as_read')}
                  </Dropdown.Item>
                )}
                {!item.publish_at && item.actions.can_mute && (
                  <Dropdown.Item onClick={() => onToggleMute(item.id)}>
                    {!item.has_muted
                      ? t('social:feed_item_message_mute')
                      : t('social:feed_item_message_unmute')}
                  </Dropdown.Item>
                )}
                {!item.publish_at && item.actions.can_mark_important && (item.is_important ? (
                  <ConfirmButton
                    danger
                    key="remove"
                    title={t('social:feed_item_message_default_confirm_remove_important_title')}
                    onConfirm={() => onToggleImportant(item.id, false)}
                  >
                    <Dropdown.Item>{t('social:feed_item_message_remove_important')}</Dropdown.Item>
                  </ConfirmButton>
                ) : (
                  <ConfirmButton
                    key="mark"
                    title={t('social:feed_item_message_default_confirm_mark_as_important_title')}
                    onConfirm={() => onToggleImportant(item.id, true)}
                  >
                    <Dropdown.Item>{t('social:feed_item_message_mark_as_important')}</Dropdown.Item>
                  </ConfirmButton>
                ))}
                {item.actions.can_disable_comments && (item.can_comment ? (
                  <ConfirmButton
                    danger
                    key="disable_comments"
                    title={t('social:feed_item_message_default_confirm_disable_comments_title')}
                    onConfirm={() => onToggleComments(item.id, false)}
                  >
                    <Dropdown.Item>{t('social:feed_item_message_disable_comments')}</Dropdown.Item>
                  </ConfirmButton>
                ) : (
                  <ConfirmButton
                    key="enable_comments"
                    title={t('social:feed_item_message_default_confirm_enable_comments_title')}
                    onConfirm={() => onToggleComments(item.id, true)}
                  >
                    <Dropdown.Item>{t('social:feed_item_message_enable_comments')}</Dropdown.Item>
                  </ConfirmButton>
                ))}
                {item.actions.can_delete && (
                  <ConfirmButton
                    danger
                    title={t('social:feed_item_message_default_confirm_remove_title')}
                    description={t('social:feed_item_message_default_confirm_remove_description')}
                    onConfirm={() => onDelete(item.id)}
                    confirmText={t('social:feed_item_message_default_confirm_remove')}
                  >
                    <Dropdown.Item danger>{t('social:feed_item_message_default_confirm_remove')}</Dropdown.Item>
                  </ConfirmButton>
                )}
              </Dropdown>
            )}
          </div>

          <Profile
            user={user}
            meta={!shouldHideParent && (
              <>
                <Icon type="chevron_right" />
                <span>{item.created_in?.values}</span>
              </>
            )}
            enablePopover={item.user_id !== loggedUser.id && !isChannel}
          >
            <div>
              <small className={item.publish_at && 'Message__PublishAt'}>
                <Icon key="icon" type="access_time" />
                {item.publish_at ? (
                  t('social:feed_item_message_default_planned_at', {
                    datetime: moment(item.publish_at).format(`dddd D MMMM [${t('social:feed_item_message_default_at')}] LT`),
                  })
                ) : moment(item.created_at).calendar()}
              </small>
              {item.created_in.type === EParentTypes.EVENT && (
                <>
                  <span>·</span>
                  <small>
                    <Icon key="icon" type="event" />
                    {t('social:feed_item_message_event')}
                  </small>
                </>
              )}
            </div>
          </Profile>
        </div>
      </Card.Header>

      <div className="Message__content fs-exclude">
        {createContent()}
      </div>
      {/* @ts-expect-error */}
      {videos.map((video) => (
        <div key={video.id} className="Message__content">
          <Attachment
            item={video}
            onOpen={() => AnalyticsService.track(EEventNames.OPENED_VIDEO)}
          />
        </div>
      ))}
      <Attachments items={attachments} message={item} />
      <Files items={files} message={item} />
    </div>
  );
};

export default DefaultMessageContentComponent;
