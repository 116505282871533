import React from 'react';
import { Trans } from 'react-i18next';

import Icon from '../../../../common/components/icon';
import Modal from '../../../../common/components/modal';
import AsyncList from '../../../../common/components/list/async';
import ImageItem from '../../../../common/components/image-item/user';

const createClassName = (selected, disabled) => (
  ['Poll__Option', selected ? 'Poll__Option--selected' : null, disabled ? 'Poll__Option--disabled' : null].filter((c) => !!c).join(' ')
);

const PollOptionComponent = ({
  item, message, poll, percentage, selected, onSelect, onFetchResults,
}) => {
  return (
    <div className={createClassName(selected, !onSelect)}>
      <div className="Poll__Option__Checkbox" onClick={!selected && onSelect ? onSelect : undefined}>
        {selected && <Icon type="check" />}
      </div>
      <div className="Poll__Option__outter">
        <div className="Poll__Option__bar" onClick={!selected && onSelect ? onSelect : undefined}>
          <div className="Poll__Option__bar__fill" style={{ width: `${Math.max(percentage || 0, 0.1)}%` }}>
            <div
              className="Poll__Option__bar__label"
              style={{ width: `calc(${100 * (100 / Math.max(percentage || 0, 0.1))}% - 32px)` }}
            >
              {item.text}
            </div>
          </div>
          <div className="Poll__Option__percentage">
            {Math.round(percentage) || 0}
            %
          </div>
        </div>
        <div className="Poll__Option__Results">
          <Trans i18nKey="social:poll_vote_count" values={{ count: item.vote_count }} />
          {onSelect && message.actions && message.actions.can_see_stats && (
            <Modal
              content={(
                <AsyncList
                  data={{
                    onFetch: onFetchResults,
                    filter: {
                      id: item.id,
                      pollId: poll.id,
                    },
                  }}
                  renderRow={ImageItem}
                  rowProps={{
                    className: 'Poll__Option__Results__User',
                  }}
                  placeholder={(
                    <Trans i18nKey="social:poll_placeholder_nobody_voted" />
                  )}
                />
              )}
            >
              <a>
                &nbsp;·&nbsp;
                <Trans i18nKey="social:poll_view_results" />
              </a>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

export default PollOptionComponent;
