import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import * as R from 'ramda';
import { Trans, withTranslation } from 'react-i18next';
import * as alert from '../../../../common/services/alert';
import { Row, MarkupEditor, Poll } from '../../../../common/components/form';
import { Button } from '../../../../common/components/button';
import editMessage from '../../actions/edit-message';

class EditMessage extends React.Component {
  static defaultProps = {
    formValues: { text: '' },
  };

  constructor(props) {
    super(props);

    this.state = {
      showPoll: false,
    };

    this.handleRemovePoll = () => this.setState({ showPoll: false });
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  UNSAFE_componentWillMount() {
    const { message, initialize } = this.props;

    const canEditPoll = message.publish_at && !message.processed_at;

    this.state.showPoll = canEditPoll && !!(message.poll && message.poll.question);

    initialize({
      text: message.text,
      poll: canEditPoll && {
        question: message.poll ? message.poll.question : '',
        options: ((message.poll && message.poll.options) || []).map((option, id) => ({ id: id + 1, value: option.text })),
      },
    });
  }

  componentDidMount() {
    // TODO: replace findDOMNode on next occasion because it has been deprecated in strict mode
    // eslint-disable-next-line react/no-find-dom-node
    const form = findDOMNode(this);

    try {
      // $FlowFixMe
      if (form) form.children[0].children[0].children[0].children[1].focus();
    } catch (err) {
      // Do nothing
    }
  }

  static props;

  async handleSubmit(values, dispatch) {
    const { message, onClose, t } = this.props;

    const data = {
      text: values.text,
    };

    if (this.state.showPoll && values.poll) {
      data.poll_question = values.poll.question;
      data.poll_options = R.pipe(R.pluck('value'), R.reject(R.isEmpty))(values.poll.options);
    }

    try {
      await dispatch(editMessage(message.id, data, !!message.publish_at));
      onClose();
    } catch (response) {
      alert.forStatus(response.status_code, {
        warning: t('social:form_edit_message_warning_posting_message'),
        error: t('social:form_edit_message_error_posting_message'),
      });
    }
  }

  render() {
    const {
      message, formValues, submitting, handleSubmit, onClose, t,
    } = this.props;

    const disabled = formValues ? formValues.text === '' : true;

    return (
      <form className="EditMessageForm Form" onSubmit={handleSubmit(this.handleSubmit)}>
        <Row>
          <MarkupEditor
            enableMentions
            target={message.created_in}
            name="text"
            placeholder={t('social:form_edit_message_text_placeholder')}
          />
        </Row>
        {this.state.showPoll && (
          <Row>
            <Poll name="poll" closePoll={this.handleRemovePoll} />
          </Row>
        )}

        <Button type="primary" buttonType="submit" disabled={disabled} isLoading={submitting}>
          <Trans i18nKey="social:form_edit_message_save_changes" />
        </Button>
        <Button onClick={onClose}>
          <Trans i18nKey="social:form_edit_message_cancel" />
        </Button>
      </form>
    );
  }
}

const EditMessageForm = reduxForm({
  initialValues: {
    text: '',
    poll: {
      question: '',
      options: [],
    },
  },
})(EditMessage);

const mapDispatchToProps = (state, props) => ({
  formValues: (state.form[props.form] || {}).values,
});

export default withTranslation()(connect(mapDispatchToProps)(EditMessageForm));
