import React, { useState } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { StaticLinkPreview } from '@common/components/static-link-preview';
import * as AnalyticsService from '@common/services/analytics';
import ImageItem from '@common/components/image-item/large';
import Card from '@common/components/card';
import Text from '@common/components/text';
import Permission from '@common/components/permission';
import Icon from '@common/components/icon';
import Attachment from '@common/components/attachment';
import Dropdown from '@common/components/dropdown';
import ConfirmButton from '@common/components/confirm-button';
import Attachments from '../attachments';
import Files from '../files';
import Poll from '../../../poll';
import { EComponentTypes } from '@common/definitions';
import type { LoggedUser, Organisation } from '@common/types/objects';
import type { Message } from '../../../../types/objects';

const { EEventNames } = AnalyticsService;

type Props = {
  loggedUser: LoggedUser;
  organisation: Organisation;
  item: Message;
  enableOrganisationActions: boolean;
  onVotePoll: (pollId: string, optionId: string, messageId: string, currentVoteResult: any[]) => Promise<any>;
  onDelete: (id: string) => Promise<void>;
  onToggleMute: (id: string) => Promise<void>;
  translateMessage: (id: string) => Promise<void>;
  onEdit: (id: string) => Promise<void>;
  t: (key: string, values?: Object) => string;
};

const OrganisationMessageContentComponent = ({
  item,
  loggedUser,
  organisation,
  enableOrganisationActions,
  translateMessage,
  onVotePoll,
  onToggleMute,
  onEdit,
  onDelete,
}: Props) => {
  const { t } = useTranslation();
  const [showTranslation, setShowTranslation] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);

  const toggleTranslation = async () => {
    if (showTranslation) {
      setShowTranslation(false);
    } else {
      if (!item.translated_text || !item.translated_text[loggedUser.translation_language.locale]) {
        setIsTranslating(true);

        await translateMessage(item.id);
      }

      setIsTranslating(false);
      setShowTranslation(true);
    }
  };

  const {
    // @ts-expect-error
    attachments, videos, files, poll, link_preview,
  } = item;

  const formattedDate = moment(item.created_at).calendar();
  const text = (
    (loggedUser.translation_language && showTranslation && item.translated_text)
      && item.translated_text[loggedUser.translation_language.locale]
  ) || item.text;

  const canTranslate = item.content_language
    && loggedUser.translation_language
    && item.content_language !== loggedUser.translation_language.locale;

  return (
    <div className="Message__Inner Message--type-organisation">
      <Card.Header>
        <div className="Message__header__inner">
          <div className="pull-right">
            {item.is_important && <Icon type="star__filled" className="Message__Important" />}
            <Dropdown
              alignRight
              id={`Message__Actions-${item.id}`}
              toggle={<Icon type="more_vert" size="small" />}
            >
              {item.actions?.can_mute && (
                <Dropdown.Item onClick={() => onToggleMute(item.id)}>
                  {!item.has_muted
                    ? t('social:feed_item_message_mute')
                    : t('social:feed_item_message_unmute')}
                </Dropdown.Item>
              )}
              {enableOrganisationActions && (
                <>
                  <Dropdown.Item onClick={() => onEdit(item.workflow_id!)}>
                    {t('social:feed_item_message_organisation_edit')}
                  </Dropdown.Item>
                  <ConfirmButton
                    danger
                    title={t('social:feed_item_message_organisation_confirm_remove_title')}
                    description={t('social:feed_item_message_organisation_confirm_remove_description')}
                    onConfirm={() => onDelete(item.workflow_id!)}
                  >
                    <Dropdown.Item danger>
                      {t('social:feed_item_message_organisation_remove')}
                    </Dropdown.Item>
                  </ConfirmButton>
                </>
              )}
            </Dropdown>
          </div>
          <ImageItem
            mask
            name={organisation.name}
            image={organisation.brand_icon!}
            meta={<Icon type="public" />}
          >
            <small>
              {!item.id && <Icon type="calendar_today" />}
              {!item.id
                ? t('social:feed_item_message_organisation_planned_at', { datetime: formattedDate })
                : formattedDate}
            </small>
          </ImageItem>
        </div>
      </Card.Header>

      <div className="Message__content fs-exclude">
        {R.is(String, item.text) ? <Text enableReadMore>{text}</Text> : null}
        <Permission component={EComponentTypes.FEED_TRANSLATION}>
          {canTranslate && (
            <div className="Message__Translate" onClick={toggleTranslation}>
              {showTranslation
                ? t('social:feed_item_show_original')
                : t(isTranslating ? 'social:feed_item_translating' : 'social:feed_item_show_translation')}
            </div>
          )}
        </Permission>
      </div>
      {/* @ts-expect-error */}
      {videos.map((video) => (
        <div key={video.id} className="Message__content">
          <Attachment
            item={video}
            onOpen={() => AnalyticsService.track(EEventNames.OPENED_VIDEO)}
          />
        </div>
      ))}
      <Attachments items={attachments} message={item} />
      <Files items={files} message={item} />
      {poll && (
      <div className="Message__content">
        <Poll
          poll={poll}
          message={item}
          loggedUser={loggedUser}
          onVote={onVotePoll}
        />
      </div>
      )}
      {link_preview && (
        <StaticLinkPreview
          className="Message__LinkPreview"
          linkPreview={link_preview}
        />
      )}
    </div>
  );
};

export default OrganisationMessageContentComponent;
