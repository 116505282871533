import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import Card from '../../../../../common/components/card';
import VisibilitySensor from '../../../../../common/components/visibility-sensor';
import { Button } from '../../../../../common/components/button';
import PostCommentForm from '../../../forms/post-comment';
import DefaultMessage from './types/default';
import OrganisationMessage from './types/organisation';
import WelcomeMessage from './types/welcome';
import BirthdayMessage from './types/birthday';
import MessageInfo from './info';
import { EMessageTypes } from '../../../definitions';
import CommentsList from './comments-list';

const supportedMessageTypes = {
  [EMessageTypes.DEFAULT_MESSAGE]: DefaultMessage,
  [EMessageTypes.ORGANISATION_MESSAGE]: OrganisationMessage,
  [EMessageTypes.WELCOME_MESSAGE]: WelcomeMessage,
  [EMessageTypes.BIRTHDAY_MESSAGE]: BirthdayMessage,
};

const FeedMessageCardComponent = (props) => {
  const {
    item, loggedUser, disableActions, selectedTeamId, getComments,
    onDeleteComment, onRead, onHighFive, onUpdate, onMarkAsRead,
    onHighFiveComment
  } = props;

  const [showComments, setShowComments] = useState(!!props.showComments);
  const [isMarkingAsRead, setMarkingAsRead] = useState(false);
  const [posting, setPosting] = useState(false);

  const handleMarkAsRead = async () => {
    setMarkingAsRead(true);

    onMarkAsRead(item.id);
  };

  // If it's not one of the supported messages types
  // $FlowFixMe
  const ContentComponent = supportedMessageTypes[item.message_type];

  if (!ContentComponent) return null;

  return (
    <Card containerClassName="Message">
      <VisibilitySensor
        active={!item.seen}
        onVisible={(onRead && (() => onRead(item.object_id))) || (() => false)}
      >
        <ContentComponent {...props} onMarkAsRead={handleMarkAsRead} />
      </VisibilitySensor>

      {item.is_important && !item.has_read && (
        <div className="Message__content">
          <Button type="soft" size="fill" onClick={handleMarkAsRead} isLoading={isMarkingAsRead}>
            <Trans i18nKey="social:feed_item_message_mark_as_read" />
          </Button>
        </div>
      )}

      <MessageInfo
        message={item}
        loggedUser={loggedUser}
        disableActions={disableActions}
        onHighFive={() => onHighFive(item.id, item.workflow_id)}
        onUpdate={(values) => onUpdate(item.id, values)}
        toggleComments={() => setShowComments(!showComments)}
      />

      {showComments && item.id && (
        <Card.Content className="Message__comments">
          <CommentsList
            comments={item.comments || []}
            getComments={getComments}
            loggedUser={loggedUser}
            onDelete={onDeleteComment}
            onHighFive={onHighFiveComment}
            discussion={item}
          />
          {
            item.can_comment && (
              <PostCommentForm
                posting={posting}
                setPosting={setPosting}
                message={item}
                form={`post-comment-${item.id}`}
                loggedUser={loggedUser}
                selectedTeamId={selectedTeamId}
              />
            )
          }
        </Card.Content>
      )}
    </Card>
  );
};

FeedMessageCardComponent.defaultProps = {
  enableOrganisationActions: false,
};

export default FeedMessageCardComponent;
