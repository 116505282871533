import React from 'react';
import { connect } from 'react-redux';
import PollOption from './option';

const countToPercentage = (count, total) => (count / total) * 100;

const PollComponent = ({
  loggedUser, poll, message, onVote, fetchPollOptionResults,
}) => (
  <div className={(!onVote || poll.vote_result) ? 'Poll Poll--selected' : 'Poll'}>
    <div className="Poll__Question">{poll.question}</div>

    <div className="Poll__Options">
      {poll.options.map((option) => (
        <PollOption
          key={option.id}
          item={option}
          poll={poll}
          message={message}
          loggedUser={loggedUser}
          onFetchResults={fetchPollOptionResults}
          selected={!!(poll.vote_result && poll.vote_result.includes(option.id))}
          percentage={countToPercentage(option.vote_count, poll.total_vote_count)}
          onSelect={onVote && (() => onVote(poll.id, option.id, poll.message_id, poll.vote_result))}
        />
      ))}
    </div>
  </div>
);

const mapDispatchToProps = {
  fetchPollOptionResults: require('../../actions/fetch-poll-option-results').default,
};

export default connect(undefined, mapDispatchToProps)(PollComponent);
